import * as React from 'react';
import {useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircle from '@mui/icons-material/AccountCircle';

const pages = ['About', 'Blog', 'Chatterbug'];

export default function ApplicationBar() {
  const navigate = useNavigate();

  const handleCloseNavMenu = (event, page) => {
    console.log(page)
    if (page == "Chatterbug") {
      navigate("/chatterbug")
    } else if (page == "Blog") {
      navigate("/blog")
    } else if (page == "About") {
      navigate("/about")
    }

  };

  const handleMenu = (event) => {
    alert("Login")
  };

  /*
    return (
      <Box sx={{flexGrow: 1}}>
        <AppBar position="static" sx={{ bgcolor: "#353E47" }}>
          <Toolbar>
            <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                flexGrow: 1,
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'broadway',
                fontSize: 25,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              ChatterBug
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    );
  
  */
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static" sx={{bgcolor: "#353E47"}}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'broadway',
              fontSize: 25,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Maitreya
          </Typography>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, flexDirection: 'row-reverse'}}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={(evt) => handleCloseNavMenu(evt, page)}
                sx={{my: 2, color: 'white', display: 'block', fontFamily: 'broadway'}}
              >
                {page}
              </Button>
            ))}
          </Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}