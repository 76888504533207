import React, {Component} from "react";
import {HashRouter, Link} from 'react-router-dom';
import AppRoutes from './Routes';
import ApplicationBar from "./components/ApplicationBar";

import './styles/App.css';

class App extends Component {
    render() {
        return (
            <div style={{height: "100%"}}>
                <HashRouter>
                <ApplicationBar></ApplicationBar>                
                    <AppRoutes></AppRoutes>
                </HashRouter>
            </div>
        );
    }
}

export default App;