import React from "react";
import {Routes,Route} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import ChatterBug from './components/ChatterBug';
import Blog from './components/Blog';



const AppRoutes = () => (
	<Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/index.html" element={<Home/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/chatterbug" element={<ChatterBug/>}/>
        <Route exact path="/blog" element={<Blog/>}/>
	</Routes>
);

export default AppRoutes;
