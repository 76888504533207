import React from 'react';
import Box from '@mui/material/Box';

export default function Home() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'left'
        }}
      >
        <Box
          sx={{
            width: '60%',
            height: 150,
            borderRadius: 2,
            bgcolor: '#e4e8ec',
            marginTop: '40px',
            marginBottom: '40px',
            marginLeft: '40px',
            borderRight: '5px solid #1790A6',
            fontSize: '42px',
            padding: '20px',
            fontFamily: 'broadway'
          }}
        >
          Answer to the Ultimate Question of Life, The Universe, and Everything.
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'right'
        }}
      >
        <Box
          sx={{
            width: '60%',
            height: 100,
            borderRadius: 2,
            bgcolor: '#e4e8ec',
            marginRight: '40px',
            borderLeft: '5px solid #F3AC2B',
            fontSize: '42px',
            padding: '20px',
            fontFamily: 'broadway'
          }}
        >
        42
        </Box>
      </Box>
    </Box>
  );
}